// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-index-js": () => import("../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-games-random-letter-js": () => import("../src/pages/games/random-letter.js" /* webpackChunkName: "component---src-pages-games-random-letter-js" */),
  "component---src-pages-indeed-html-js": () => import("../src/pages/indeed.html.js" /* webpackChunkName: "component---src-pages-indeed-html-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

